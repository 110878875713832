<template>
	<div class="dynamic">
		<div class="header">
			<div class="type">
				<div class="center">文章中心</div>
				<div class="pay">已付费</div>
			</div>
			<div class="type_item">
				<div class="type_scroll">
					<div v-for="(i,k) in 2" :key="k" :class="k==shopkey?'bor':''" @click="SwitchShop(k)">智慧商城{{k}}</div>
				</div>
			</div>
		</div>
		<div class="box">
			<div class="shops">
				<div v-for="(i,k) in 5" :key="k">
					<div class="img"></div>
					<div class="text">
						<div class="tit">
							<span>优化</span>
							<span>|</span>
							<span>后台页面样式更新</span>
						</div>
						<div class="direction">
							我们对供应链中台后台列表的样式进行了全面的优化升级，特别是针对搜索部分进行了重点改进，以商品、订单、财务三个页面为例，为大家展示优化后样式
						</div>
						<div class="name">
							<div class="names">
								<span>芸众科技</span>
								<span>|</span>
								<span>供应链中台</span>
							</div>
							<div class="timer">
								2024-08-15
							</div>
						</div>
					</div>
				</div>
				<div class="girl">

				</div>
			</div>
			<div class="popular">
				<div class="hotarticle">
					<div class="title">
						<img src="@/static/img/fire.png" alt="" />
						<div>热门文章</div>
					</div>
					<div class="text">

					</div>
				</div>
				<div class="list" v-for="(i,k) in 2" :key="k"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				shopkey: 0,
			}
		},
		mounted() {
			document.title = '公司动态-广东御丸堂生物医药有限公司';
		},
		methods: {
			SwitchShop(key) {
				this.shopkey = key
			}
		}
	}
</script>

<style lang="scss">
	.dynamic {
		width: 100%;
		height: auto;
		margin-top: 100px;

		.header {
			width: 100%;
			height: 100px;
			background-color: #fff;
			border-top: 2px solid #0978f7;

			.type {
				width: 1200px;
				height: 50px;
				margin: 0 auto;
				display: flex;

				.center {
					width: 100px;
					height: 35px;
					line-height: 35px;
					font-size: 16px;
					font-weight: bold;
					color: #fff;
					background-color: #0978f7;
					margin-top: 15px;
					border-radius: 20px;
				}

				.pay {
					width: 60px;
					height: 35px;
					line-height: 35px;
					font-size: 16px;
					font-weight: bold;
					color: #000;
					margin: 15px 0px 0px 10px;
				}
			}

			.type_item {
				width: 1200px;
				height: 45px;
				margin: 0 auto;

				.type_scroll {
					width: 100%;
					height: 45px;
					display: flex;
					padding: 0px 0px 0px 22px;

					div {
						width: auto;
						height: 45px;
						line-height: 50px;
						margin: 0px 48px 0px 10px;
						font-size: 14px;
					}

					.bor {
						border-bottom: 3px solid blue;
					}
				}


			}
		}

		.box {
			width: 1200px;
			min-height: 800px;
			margin: 20px auto;
			display: flex;
			justify-content: space-between;
			padding-bottom: 50px;

			.shops {
				width: 848px;
				min-height: 800px;
				background-color: #fff;
				border-radius: 10px;

				>div {
					width: 848px;
					height: 185px;
					display: flex;

					.img {
						width: 210px;
						height: 160px;
						border-radius: 10px;
						background-color: #0978f7;
						margin: 20px 16px 5px 20px;
					}

					.text {
						width: 570px;
						height: 150px;
						margin-top: 25px;

						.tit {
							width: 570px;
							height: 25px;
							font-size: 20px;
							font-weight: bold;
							display: flex;
							margin-top: 12px;
							color: #000;

							span {
								margin-right: 8px;
							}

							span:nth-child(2) {
								margin-top: 2px;
							}
						}

						.direction {
							width: 570px;
							height: 40px;
							margin-top: 18px;
							font-size: 14px;
							color: #999;
							text-align: left;
						}

						.name {
							width: 570px;
							height: 35px;
							display: flex;
							font-size: 12px;
							margin-top: 25px;
							justify-content: space-between;

							.names {
								span {
									margin-right: 5px;
									color: #999;
								}

								span:nth-child(3) {
									color: blue;
								}
							}

							.timer {
								margin-top: 3px;
								color: #999;
							}
						}
					}
				}
			}

			.popular {
				width: 340px;
				min-height: 800px;
				background-color: #fff;
				border-radius: 10px;

				.hotarticle {
					width: 310px;
					height: 320px;
					margin: 20px auto;

					.title {
						width: 310px;
						height: 40px;
						display: flex;
						margin: 15px 0px 10px 0px;

						img {
							width: 30px;
							height: 30px;
							margin-top: 5px;
						}

						div {
							width: 100px;
							height: 20px;
							margin-top: 10px;
							margin-left: 0px;
							font-size: 20px;
							font-weight: bold;
							color: #000;
						}
					}

					.text {
						width: 310px;
						height: 230px;
						background-color: greenyellow;
					}
				}



				.list {
					width: 310px;
					height: 230px;
					border-radius: 10px;
					overflow: hidden;
					background-color: aqua;
					margin: 20px auto;
				}
			}
		}
	}

	@media screen and (max-width: 900px) {
		.dynamic {
			width: 100%;
			height: auto;
			margin-top: 2rem;

			.header {
				width: 27rem;
				height: 8rem;
				background-color: #fff;
				border-top: 2px solid #0978f7;

				.type {
					width: 27rem;
					height: 3rem;
					margin: .5rem auto;
					display: flex;

					.center {
						width: 5rem;
						height: 2rem;
						line-height: 2rem;
						font-size: .8rem;
						font-weight: bold;
						color: #fff;
						background-color: #0978f7;
						margin-top: .9rem;
						margin-left: 1rem;
						border-radius: 20px;
					}

					.pay {
						width: 4rem;
						height: 2rem;
						line-height: 2rem;
						font-size: .9rem;
						font-weight: bold;
						color: #000;
						margin: .9rem 0rem 0rem 1rem;
					}
				}

				.type_item {
					width: 27rem;
					height: 4rem;
					margin: .5rem auto;
					overflow-x: auto;

					&::-webkit-scrollbar {
						display: none;
						/* 针对Webkit浏览器 */
					}

					.type_scroll {
						width: 50rem;
						height: 4rem;
						padding: 0px 0px 0px 1.3rem;
						display: flex;

						div {
							min-width: 4rem;
							height: 3.6rem;
							line-height: 3.8rem;
							margin: 0px 2.5rem 0rem 0rem;
							font-size: .8rem;
						}

						.bor {
							border-bottom: 3px solid blue;
						}
					}
				}
			}

			.box {
				width: 27rem;
				min-height: 20rem;
				margin: 1.2rem auto;
				padding-bottom: 3rem;
				display: flex;
				flex-wrap: wrap;

				.shops {
					width: 26rem;
					min-height: 15rem;
					background-color: #fff;
					margin: .5rem .5rem;
					order: 2;

					>div {
						width: 26rem;
						height: 10rem;
						display: flex;
						margin: .5rem;
						border-radius: 5px;

						.img {
							width: 11rem;
							height: 8rem;
							border-radius: 10px;
							background-color: #0978f7;
							margin: 1rem .8rem .3rem 0rem;
						}

						.text {
							width: 14rem;
							height: .9rem;
							margin-top: 1.5rem;

							.tit {
								width: 13rem;
								height: 1.5rem;
								font-size: 1rem;
								font-weight: bold;
								display: flex;
								margin-top: -0.5rem;
								color: #000;

								span {
									margin-right: .3rem;
								}

								span:nth-child(2) {
									margin-top: 0.1rem;
								}
							}

							.direction {
								width: 13rem;
								height: 3.2rem;
								margin-top: .5rem;
								font-size: .8rem;
								color: #999;
								text-align: left;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 3;
								/* 设置行数 */
								overflow: hidden;
							}

							.name {
								width: 13rem;
								height: 2.1rem;
								display: flex;
								font-size: .7rem;
								margin-top: 1.5rem;
								justify-content: space-between;

								.names {
									span {
										margin-right: .3rem;
										color: #999;
									}

									span:nth-child(3) {
										color: blue;
									}
								}

								.timer {
									margin-top: .3rem;
									color: #999;
								}
							}
						}
					}
				}

				.popular {
					width: 26rem;
					min-height: 30rem;
					background-color: #fff;
					border-radius: 5px;
					order: 1;
					display: flex;
					flex-wrap: wrap;
					margin-left: .5rem;

					.hotarticle {
						width: 25rem;
						min-height: 15rem;
						margin: 0.5rem auto;

						.title {
							width: 25rem;
							height: 2.5rem;
							display: flex;
							margin: 1rem 0rem .7rem 0rem;

							img {
								width: 2rem;
								height: 2rem;
								margin-top: .3rem;
							}

							div {
								width: 5rem;
								height: 2rem;
								margin-top: .7rem;
								margin-left: 0px;
								font-size: 1.2rem;
								font-weight: bold;
								color: #000;
							}
						}

						.text {
							width: 25rem;
							height: 15rem;
							background-color: greenyellow;
						}
					}

					.list {
						width: 12.3rem;
						height: 8rem;
						border-radius: 5px;
						overflow: hidden;
						background-color: aqua;
						margin: -2rem 0rem 0rem 0.5rem;
					}
				}
			}
		}
	}
</style>